.bar {
    height: 100%;
    width: 100%;
    li {
        float: left; height: 100%;
        width: 25%;
        padding:15px 30px;
        a{
            color:red; //todo set color
            border: 1px solid black; //todo set color
            display: block;
            width: 100%;
            text-align: center;
            font-size: 50px;
            text-decoration: none;
        }
    }
}