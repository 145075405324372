.container {
    height: 100%;
}

.content {
    background: darkgreen; //todo set color
    height: 90%;
}

.menuBar {
    background: blue; //todo set color
    height: 10%;
}